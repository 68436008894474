import { render, staticRenderFns } from "./studentsByFamilyIncome.vue?vue&type=template&id=294503c3&scoped=true&"
import script from "./studentsByFamilyIncome.js?vue&type=script&lang=js&"
export * from "./studentsByFamilyIncome.js?vue&type=script&lang=js&"
import style0 from "./studentsByFamilyIncome.scss?vue&type=style&index=0&id=294503c3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294503c3",
  null
  
)

export default component.exports