import ReportStudent from '@/shared/http/repositories/socialProject/report-student'
import VueHighcharts from 'vue2-highcharts'
import { loading } from '@/shared/utils/UIHelper'

export default {
  components: {
    VueHighcharts
  },
  data () {
    return {
      isChartLoading: false,
      result: [],
      options: {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45
          },
          height: '800px'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            events: {
              click: this.LoadStudents
            },
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y}'
            }
          },
          pie: {
            innerSize: 100,
            depth: 45
          }
        },
        series: []
      }
    }
  },
  mounted () {
    loading.push()
    ReportStudent.GetTotalByFamilyIncome()
      .then(response => {
        let itemArr = []
        let dataArr = []
        var totalStudents = 0
        this.result = response.data.result

        this.result.forEach(element => {
          itemArr = {
            'id': element.id,
            'name': element.name,
            'y': element.count
          }
          totalStudents += element.count
          dataArr.push(itemArr)
        });

        let chartData = [
          {
            name: 'Número de Alunos',
            data: dataArr,
            colorByPoint: true,
            colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1']
          }
        ]

        setTimeout(() => {
          this.$refs.pieChart.removeSeries(1)
          this.$refs.pieChart.addSeries(chartData[0])

          this.$emit('totalStudents', totalStudents)
          loading.pop()
        }, 2000)
      })
      .catch(e => {
        loading.pop()
        console.log(`Erro ao tentar obter os dados para o gráfico "Alunos Por Renda Familiar": \n ${e}`)
      })
  },
  methods: {
    LoadStudents (e) {
      loading.push()
      if (e.point.id) {
        ReportStudent.GetStudentsByFamilyIncome(e.point.id)
          .then(response => {
            this.$emit('selectSchool', response.data.result)
            loading.pop()
          })
          .catch(e => {
            loading.pop()
            console.log(`Erro ao tentar obter os dados para o gráfico "Alunos Por Renda Familiar": \n ${e}`)
          })
      }
    }
  }
}
